<template>
    <div class="text-center" ref="ssdsd">
        <v-dialog content-class="elevation-0" flat style="opacity: 0" v-model="subscribeDialog">
            <v-snackbar top v-model="notification.show" :color="notification.color">
                <v-icon>{{ notification.icon }}</v-icon
                ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
            </v-snackbar>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="darkbtn" dark v-bind="attrs" v-on="on">
                    Upgrade now
                    <v-icon small right dark class="ml-1">mdi-rocket-launch-outline</v-icon>
                </v-btn>
                <v-btn v-else color="orange" small dark id="gradient" v-bind="attrs" v-on="on">
                    <v-icon small right dark class="ml-0 mr-2">mdi-chess-queen</v-icon>
                    Upgrade
                </v-btn>
            </template>
            <div class="close-btn-container">
                <v-btn small color="white" rounded outlined @click="subscribeDialog = false" class="close-btn"> <v-icon>mdi-close</v-icon> close </v-btn>
            </div>
            <!-- <v-row>
                <v-btn small rounded outlined @click="subscribeDialog = false" class="ml-auto close-btn">
                    <v-icon>mdi-close</v-icon> close
                </v-btn>
            </v-row> -->
            <div style="display: inline-flex; flex-direction: row">
                <v-card max-width="370px" class="ml-auto d-flex flex-column" :style="campaign == 'fb_form1' ? 'border: 4px solid var(--v-primary-base);' : ''">
                    <v-sheet v-if="campaign == 'fb_form1'" color="primary" dark id="earlybirdSheet" rounded="xl" elevation="6">
                        <center>
                            <div class="headingsheet2 mt-6">
                                <span class="plan-text pt-5"> Early Bird (save ~30%) </span>
                            </div>
                        </center>
                    </v-sheet>
                    <v-card-text>
                        <div :class="campaign == 'fb_form1' ? 'text-center mt-3' : 'text-center mt-11'">
                            <img width="130px" :src="rocket01" id="rocket01" />
                            <br />
                            <h3>
                                <span class="title-quaro">Quaro</span>
                                <span class="title-after">{{ tabItems[0].name }}</span>
                            </h3>
                            <br />
                            <v-btn-toggle v-model="yearMonthSwitch" class="mx-auto">
                                <v-btn small outlined color="darkgreen"> monthly </v-btn>
                                <v-btn id="right_toggle" small outlined color="darkgreen"> yearly </v-btn>
                                <v-badge bordered color="error" overlap>
                                    <template v-slot:badge> -10% </template>
                                </v-badge>
                            </v-btn-toggle>
                        </div>

                        <v-list class="mt-3">
                            <v-tooltip top v-for="attribute in tabItems[0].attributes" v-bind:key="attribute.title">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item v-bind="attrs" v-on="on">
                                        <v-list-item-icon>
                                            <v-icon>{{ attribute.icon }}</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ attribute.title }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ attribute.subtitle }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                                <span v-if="attribute.tooltip">{{ attribute.tooltip }}</span>
                            </v-tooltip>
                        </v-list>
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <!-- style 2 -->
                    <div class="price-style mx-auto">
                        <del>
                            <span class="amount">€ {{ yearMonthSwitch ? "969" : "89" }},<sup>99</sup></span>
                        </del>
                        <ins>
                            <span class="amount">
                                €{{ getAmount(yearMonthSwitch ? tabItems[0].price.annual : tabItems[0].price.month) }},<sup
                                    >{{ getSub(yearMonthSwitch ? tabItems[0].price.annual : tabItems[0].price.month) }}
                                </sup>
                            </span>
                        </ins>
                    </div>
                    <div class="period mx-auto">
                        <span class="mx-auto">
                            {{ yearMonthSwitch ? "yearly" : "monthly" }}
                        </span>
                    </div>

                    <!-- <div class="price mx-auto" ref="switch">
                        <h2>
                            {{ yearMonthSwitch ? tabItems[0].price.annual : tabItems[0].price.month }}€ /{{
                                yearMonthSwitch ? "year" : "month"
                            }}
                        </h2>
                    </div> -->

                    <v-card-actions>
                        <stripe-checkout ref="checkout" :pk="publishableKey" :session-id="session_id" />

                        <v-btn
                            :loading="loadingPackageNull"
                            class="subscribe1btn"
                            color="primary"
                            block
                            rounded
                            @click="submitStripe(yearMonthSwitch ? tabItems[0].lineItemsAnnual : tabItems[0].lineItemsMonth, tabItems[0].id)">
                            <span>Subscribe </span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
                <v-card max-width="370px" class="d-flex mx-3 flex-column" :style="campaign != 'fb_form1' ? 'border: 4px solid var(--v-primary-base);' : ''">
                    <v-sheet v-if="campaign != 'fb_form1'" color="primary" dark id="searchsheet" rounded="xl" elevation="6">
                        <center>
                            <div class="headingsheet2 mt-6">
                                <span class="plan-text"> best value </span>
                            </div>
                        </center>
                    </v-sheet>
                    <v-card-text>
                        <div :class="campaign == 'fb_form1' ? 'text-center mt-11' : 'text-center mt-3'">
                            <img width="130px" :src="rocket02" />
                            <br />
                            <h3>
                                <span class="title-quaro">Quaro</span>
                                <span class="title-after">{{ tabItems[1].name }}</span>
                            </h3>
                            <br />
                            <v-btn-toggle v-model="yearMonthSwitch" class="mx-auto">
                                <v-btn small outlined color="darkgreen"> monthly </v-btn>
                                <v-btn small outlined color="darkgreen"> yearly </v-btn>
                                <v-badge bordered color="error" overlap>
                                    <template v-slot:badge>-10%</template>
                                </v-badge>
                            </v-btn-toggle>
                        </div>

                        <v-list double-line class="mt-3">
                            <v-tooltip top v-for="attribute in tabItems[1].attributes" v-bind:key="attribute.title">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item v-bind="attrs" v-on="on">
                                        <v-list-item-icon>
                                            <v-icon>{{ attribute.icon }}</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ attribute.title }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ attribute.subtitle }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                                <span v-if="attribute.tooltip">{{ attribute.tooltip }}</span>
                            </v-tooltip>
                        </v-list>
                    </v-card-text>
                    <v-spacer></v-spacer>

                    <div class="price-style mx-auto">
                        <ins>
                            <span class="amount">
                                €{{ getAmount(yearMonthSwitch ? tabItems[1].price.annual : tabItems[1].price.month) }},<sup
                                    >{{ getSub(yearMonthSwitch ? tabItems[1].price.annual : tabItems[1].price.month) }}
                                </sup>
                            </span>
                        </ins>
                    </div>
                    <div class="period mx-auto">
                        <span class="mx-auto">
                            {{ yearMonthSwitch ? "yearly" : "monthly" }}
                        </span>
                    </div>

                    <!-- <div class="price mx-auto">
                        <h2>
                            {{ yearMonthSwitch ? tabItems[1].price.annual : tabItems[1].price.month }}€ /{{
                                yearMonthSwitch ? "year" : "month"
                            }}
                        </h2>
                    </div> -->
                    <v-card-actions>
                        <stripe-checkout ref="checkout" :pk="publishableKey" :session-id="session_id" />

                        <v-btn
                            :loading="loadingPackageOne"
                            color="primary"
                            block
                            rounded
                            @click="submitStripe(yearMonthSwitch ? tabItems[1].lineItemsAnnual : tabItems[1].lineItemsMonth, tabItems[1].id)">
                            <span>Subscribe </span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
                <v-card max-width="370px" class="mr-auto d-flex flex-column">
                    <v-card-text>
                        <div class="text-center mt-11">
                            <img width="130px" :src="rocket03" />
                            <br />
                            <h3>
                                <span class="title-quaro">Quaro</span>
                                <span class="title-after">{{ tabItems[2].name }}</span>
                            </h3>
                            <br />
                            <v-btn-toggle v-model="yearMonthSwitch" class="mx-auto">
                                <v-btn small outlined color="darkgreen"> monthly </v-btn>
                                <v-btn small outlined color="darkgreen"> yearly </v-btn>
                                <v-badge bordered color="error" overlap>
                                    <template v-slot:badge>-10%</template>
                                </v-badge>
                            </v-btn-toggle>
                        </div>

                        <v-list double-line class="mt-3">
                            <v-tooltip top v-for="attribute in tabItems[2].attributes" v-bind:key="attribute.title">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item v-bind="attrs" v-on="on">
                                        <v-list-item-icon>
                                            <v-icon>{{ attribute.icon }}</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ attribute.title }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ attribute.subtitle }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                                <span v-if="attribute.tooltip">{{ attribute.tooltip }}</span>
                            </v-tooltip>
                        </v-list>
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <div class="price-style mx-auto">
                        <ins>
                            <span class="amount">
                                €{{ getAmount(yearMonthSwitch ? tabItems[2].price.annual : tabItems[2].price.month) }},<sup
                                    >{{ getSub(yearMonthSwitch ? tabItems[2].price.annual : tabItems[2].price.month) }}
                                </sup>
                            </span>
                        </ins>
                    </div>
                    <div class="period mx-auto">
                        <span class="mx-auto">
                            {{ yearMonthSwitch ? "yearly" : "monthly" }}
                        </span>
                    </div>
                    <!-- <div class="price mx-auto">
                        <h2>
                            {{ yearMonthSwitch ? tabItems[2].price.annual : tabItems[2].price.month }}€ /{{
                                yearMonthSwitch ? "year" : "month"
                            }}
                        </h2>
                    </div> -->
                    <v-card-actions>
                        <stripe-checkout ref="checkout" :pk="publishableKey" :session-id="session_id" />

                        <v-btn
                            :loading="loadingPackageTwo"
                            color="primary"
                            block
                            rounded
                            @click="submitStripe(yearMonthSwitch ? tabItems[2].lineItemsAnnual : tabItems[2].lineItemsMonth, tabItems[2].id)">
                            <span>Subscribe </span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </div>
        </v-dialog>
    </div>
</template>

<script>
    import { StripeCheckout } from "@vue-stripe/vue-stripe"
    export default {
        name: "Checkout",
        props: {
            show: Boolean,
            darkbtn: Boolean
        },
        components: {
            StripeCheckout
        },
        data: () => ({
            notification: { show: false },
            loadingPackageNull: false,
            loadingPackageOne: false,
            loadingPackageTwo: false,
            auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
            session_id: null,
            checkout_data: {
                success_url: process.env.VUE_APP_STRIPE_SUCCESS_URL,
                cancel_url: process.env.VUE_APP_STRIPE_CANCEL_URL,
                payment_method_types: ["card", "sofort", "sepa_debit", "paypal"],
                line_items: null,
                mode: "subscription",
                client_reference_id: localStorage.id,
                customer: localStorage.username,
                customer_email: localStorage.user,
                metadata: localStorage.company_id
            },
            publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
            subscribeDialog: false,
            yearMonthSwitch: 0,
            yearMonthLabel: "Yearly",
            rocket01: require("../../assets/icons/__rocket01.svg"),
            rocket02: require("../../assets/icons/__rocket02.svg"),
            rocket03: require("../../assets/icons/__rocket03.svg"),
            tabItems: [
                {
                    id: 0,
                    name: "Single",
                    lineItemsMonth: [{ price: process.env.VUE_APP_SINGLE_PRICE_ID_MONTHLY_EARLY_BIRD, quantity: 1 }],
                    lineItemsAnnual: [{ price: process.env.VUE_APP_SINGLE_PRICE_ID_YEARLY_EARLY_BIRD, quantity: 1 }],
                    price: {
                        month: "59,99",
                        annual: "639,99"
                    },
                    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ullamcorper diam congue felis consequat fringilla. Phasellus ac orci nec velit tristique lacinia quis in purus. Maecenas eu sagittis felis, id egestas augue. Cras eu sapien vehicula, cursus lectus vitae, efficitur nunc. Phasellus sodales faucibus mi non tincidunt. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla accumsan arcu sed dictum mattis. Suspendisse in placerat nisi.",
                    attributes: [
                        {
                            title: "100.000 Keywords",
                            subtitle: "Retrieve up to 100.000 keywords",
                            icon: "mdi-magnify",
                            tooltip: "You can search up to 100.000 keywords every month"
                        },
                        {
                            title: "Single User License",
                            subtitle: "One user to rule them all",
                            icon: "mdi-account-circle-outline",
                            tooltip: "The perfect package for a one man show"
                        },
                        {
                            title: "Unlimited use of all functions",
                            subtitle: "Ideas, Suggest, Historical...",
                            icon: "mdi-check-circle-outline",
                            tooltip: "Use all features and modules without limits and create as many projects and collections as you want"
                        },
                        {
                            title: "Share your Collections",
                            subtitle: "You can share all your collections",
                            icon: "mdi-share-variant-outline",
                            tooltip: "Share your projects and collections easily with your colleagues or customers - no need for excel!"
                        },
                        {
                            title: "Automated updating",
                            subtitle: "Keep your data always up to date",
                            icon: "mdi-update",
                            tooltip: "Quaro automatically updates the search volume of your collections"
                        }
                    ]
                },
                {
                    id: 1,
                    name: "Advanced",
                    lineItemsMonth: [
                        {
                            price: process.env.VUE_APP_ADVANCED_PRICE_ID_MONTHLY,
                            quantity: 1
                        }
                    ],
                    lineItemsAnnual: [{ price: process.env.VUE_APP_ADVANCED_PRICE_ID_YEARLY, quantity: 1 }],
                    price: {
                        month: "199,99",
                        annual: "2158,99"
                    },
                    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ullamcorper diam congue felis consequat fringilla. Phasellus ac orci nec velit tristique lacinia quis in purus. Maecenas eu sagittis felis, id egestas augue. Cras eu sapien vehicula, cursus lectus vitae, efficitur nunc. Phasellus sodales faucibus mi non tincidunt. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla accumsan arcu sed dictum mattis. Suspendisse in placerat nisi.",
                    attributes: [
                        {
                            title: "500.000 Keywords",
                            subtitle: "Retrieve up to 500.000 keywords",
                            icon: "mdi-magnify",
                            tooltip: "You can search up to half a million keywords every month"
                        },
                        {
                            title: "Unlimited User License",
                            subtitle: "Create as many user as you need",
                            icon: "mdi-account-supervisor-circle-outline",
                            tooltip: "There is no user limit in this package - use Quaro with all your colleagues"
                        },
                        {
                            title: "Unlimited use of all functions",
                            subtitle: "Ideas, Suggest, Historical...",
                            icon: "mdi-check-circle-outline",
                            tooltip: "Use all features and modules without limits and create as many projects and collections as you want"
                        },
                        {
                            title: "Share your Collections",
                            subtitle: "You can share all your collections",
                            icon: "mdi-share-variant-outline",
                            tooltip: "Share your projects and collections easily with your colleagues or customers - no need for excel!"
                        },
                        {
                            title: "Automated updating",
                            subtitle: "Keep your data always up to date",
                            icon: "mdi-update",
                            tooltip: "Quaro automatically updates the search volume of your collections"
                        }
                    ]
                },

                {
                    id: 2,
                    name: "Pro",
                    lineItemsMonth: [{ price: process.env.VUE_APP_PRO_PRICE_ID_MONTHLY, quantity: 1 }],
                    lineItemsAnnual: [{ price: process.env.VUE_APP_PRO_PRICE_ID_YEARLY, quantity: 1 }],
                    price: {
                        month: "369,99",
                        annual: "3994,99"
                    },
                    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ullamcorper diam congue felis consequat fringilla. Phasellus ac orci nec velit tristique lacinia quis in purus. Maecenas eu sagittis felis, id egestas augue. Cras eu sapien vehicula, cursus lectus vitae, efficitur nunc. Phasellus sodales faucibus mi non tincidunt. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla accumsan arcu sed dictum mattis. Suspendisse in placerat nisi.",
                    attributes: [
                        {
                            title: "1.000.000 Keywords",
                            subtitle: "Retrieve up to 1.000.000 keywords",
                            icon: "mdi-magnify",
                            tooltip: "You can search up to one million keywords every month"
                        },
                        {
                            title: "Unlimited User License",
                            subtitle: "Create as many user as you need",
                            icon: "mdi-account-supervisor-circle-outline",
                            tooltip: "There is no user limit in this package - use Quaro with all your colleagues"
                        },
                        {
                            title: "Unlimited use of all functions",
                            subtitle: "Ideas, Suggest, Historical...",
                            icon: "mdi-check-circle-outline",
                            tooltip: "Use all features and modules without limits and create as many projects and collections as you want"
                        },
                        {
                            title: "Share your Collections",
                            subtitle: "You can share all your collections",
                            icon: "mdi-share-variant-outline",
                            tooltip: "Share your projects and collections easily with your colleagues or customers - no need for excel!"
                        },
                        {
                            title: "Automated updating",
                            subtitle: "Keep your data always up to date",
                            icon: "mdi-update",
                            tooltip: "Quaro automatically updates the search volume of your collections"
                        }
                        // {
                        //     title: 'Single-Sign-On (SSO)',
                        //     subtitle: 'Use your company Login System',
                        //     icon: 'mdi-login',
                        //     tooltip: ''
                        // },
                        // {
                        //     title: 'Unlimited training courses',
                        //     subtitle: 'Use your company Login System',
                        //     icon: 'mdi-face-agent',
                        //     tooltip: ''
                        // },
                    ]
                }
            ],
            tab: 1
        }),
        computed: {
            campaign() {
                return this.$store.state.campaign
            }
        },
        methods: {
            getAmount(price) {
                try {
                    let split = price.split(",")
                    return split[0]
                } catch (error) {
                    return "error"
                }
            },
            getSub(price) {
                try {
                    let split = price.split(",")
                    return split[1]
                } catch (error) {
                    return "error"
                }
            },
            submitStripe(lineItems, itemID) {
                // status if schon started
                this.checkout_data.line_items = lineItems
                if (itemID == 0) {
                    this.loadingPackageNull = true
                } else if (itemID == 1) {
                    this.loadingPackageOne = true
                } else {
                    this.loadingPackageTwo = true
                }
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/checkout/init", this.checkout_data, {
                        headers: { Authorization: this.auth }
                    })
                    .then((response) => {
                        console.log(response)
                        if (!response.body.error) {
                            this.session_id = response.body.session_id
                            this.$refs.checkout.redirectToCheckout()
                        } else {
                            console.log("Active Subscription found! Please contact support via chat or via email")
                            this.notification = this.$helper.createNotification(
                                "Active Subscription found! Please contact support via chat or via email! contact@quaro.io",
                                "mdi-alert-circle-outline",
                                "#FCBA03"
                            )
                        }
                    })
                    .catch((error) => {
                        this.notification = this.$helpers.createNotification("An error acurred! Please contact support via chat or via email! contact@quaro.io", "mdi-alert-circle-outline", "#FCBA03")
                        this.loadingPackageNull = false
                        this.loadingPackageOne = false
                        this.loadingPackageTwo = false
                        console.log(error)
                    })
            },
            checkCampaign() {
                console.log("h")
                console.log(this.campaign)
                if (this.campaign && this.campaign == "fb_form1") {
                    this.tabItems[0].lineItemsMonth = [{ price: process.env.VUE_APP_SINGLE_PRICE_ID_MONTHLY_EARLY_BIRD, quantity: 1 }]
                    this.tabItems[0].lineItemsAnnual = [{ price: process.env.VUE_APP_SINGLE_PRICE_ID_YEARLY_EARLY_BIRD, quantity: 1 }]
                    this.tabItems[0].price = {
                        month: "59,99",
                        annual: "639,99"
                    }
                }
            }
        },
        mounted() {
            // this.checkCampaign()
        }
    }
</script>
<style scoped lang="scss">
    #right_toggle {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .period {
        color: grey;
        display: flex;
        width: 30%;
        padding: 1px;
        border: 1px solid grey;
        border-radius: 5px;
    }
    .price-style {
        del {
            color: rgba(rgb(255, 0, 0), 0.5);
            text-decoration: none;
            position: relative;
            font-size: 25px;
            font-weight: 100;
            &:before {
                content: " ";
                display: block;
                width: 100%;
                border-top: 1px solid rgba(rgb(250, 0, 0), 0.8);
                border-bottom: 1px solid rgba(rgb(255, 0, 0), 0.8);
                height: 5px;
                position: absolute;
                bottom: 15px;
                left: 0;
                transform: rotate(-11deg);
            }
        }
        ins {
            font-size: 40px;
            font-weight: 100;
            text-decoration: none;
            padding-left: 0px !important;
            // padding: 1em 1em 1em 0.5em;
        }
    }
    .title-quaro {
        text-transform: uppercase;
        color: black;
    }
    .title-after {
        text-transform: uppercase;
        color: var(--v-primary-base);
    }
    .best-value-card {
        border: 4px solid var(--v-primary-base);
    }
    .v-dialog {
        box-shadow: none !important;
    }
    #earlybirdSheet {
        margin-top: -25px;
        margin-left: auto;
        margin-right: auto;
        width: 250px;
        height: 50px;
    }
    #searchsheet {
        margin-top: -25px;
        margin-left: auto;
        margin-right: auto;
        width: 200px;
        height: 50px;
    }
    .plan-text {
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 5px !important;
    }
    #gradient {
        background: rgb(28, 237, 179);
        background: linear-gradient(90deg, #009f89 0%, #e68989 75%);
    }
    .close-btn-container {
        width: 100px;
        position: fixed;
        top: 48px;
        left: calc(50% - 50px);
    }
</style>
